.deliveryRecord{
  height: 80vh;
  overflow: hidden;
  position: relative;
  padding: 15px;
  >p{
    font-size: 20px;
    text-align: center;
    margin: 10px 0 0;
    color: rgba(0, 0, 0, 1);
  }
  .loading{
    text-align: center;
    height: 100%;
    margin-top: 30%;
  }
  .content{
    height: calc(100% - 20px);
  }
  .listScroll{
    overflow: auto;
    height: 90%;
    overflow-x: hidden;
  }
  .list{
    height: 100%;
    .notList{
      text-align: center;
      margin: 0 auto 5%;
      padding-top: 20%;
      height: 60%;
      background: #fff;
      border-radius: 22px;
      >img{
        width: 150px;
        height: 150px;
      }
      >p{
        color: rgba(0, 0, 0, 1);
        font-size: 20px;
        text-align: center;
      }
      >span{
        display: block;
        text-align: center;
        font-size: 16px;
        color: rgba(0, 0, 0, 1);

      }
    }
    .listContent{
      margin: 15px 0;
      padding: 10px 15px;
      background-color: #fff;
      border: 1px solid #ECEDF2;
      box-sizing: border-box;
      border-radius: 10px;
      .listConTitle{
        width: 100%;
        vertical-align: middle;
        .positionDetail{
          margin-top: 8px;
          .topTitle{
            display: inline-block;
            vertical-align: middle;
            margin-bottom: 5px;
            width: calc(100% - 100px);
            .positionName{
              font-size: 15px;
              font-weight: 600;
              color: #1F2126;
              vertical-align: middle;
              margin: 0;
              display: inline-block;
              // max-width: calc(100% - 70px);
              max-width: calc(100% - 30px);
              overflow: hidden; /*超出部分隐藏*/
              white-space: nowrap; /*不换行*/
              text-overflow: ellipsis; /*超出部分文字以...显示*/
            }
            .urgent{
              width: 25px;
              height: 25px;
              vertical-align: middle;
              display: inline;
              margin-left: 5px;
            }
          }
          .btn{
            display: inline-block;
            text-align: right;
            width: 100px;
            .btnStatus{
              display: block;
              margin: 0;
              font-size: 12px;
              >img{
                width: 15px;
                height: 15px;
                vertical-align: middle;
                margin-right: 5px;
              }
            }
            .btnOne{
              color: rgba(254, 124, 15, 1);
            }
            .btnTwo{
              color: rgba(153, 153, 153, 1);
            }
            .btnThree{
              color: rgba(16, 38, 254, 1);

            }
          }
        }
        .itemBottom{
          display: flex;
          margin: 8px 0;
          .salary{
            flex: 1;
            .salaryItem{
              color: rgba(16, 38, 254, 1);
              font-weight: 600;
              vertical-align: middle;
              font-size: 14px;
            }
          }
          .time{
            flex: 1;
            text-align: right;
            font-size: 12px;
            color: rgba(125, 125, 125, 1);
            margin: 0;
          }
        }
        .bottomDetail{
          flex: 3;
          position: relative;
          margin: 7px 0;
          .company{
            max-width: calc(95% - 80px);
            .typeTxt{
              font-size: 14px;
              margin: 10px 0;
              color: rgba(56, 56, 56, 1);
              max-width: 100%;
              overflow: hidden;
              white-space: nowrap; /*不换行*/
              text-overflow: ellipsis;
              .strContent{
                display: inline;
                vertical-align: middle;
                margin-right: 6px;
                color: rgba(166, 166, 166, 1);
              }
            }
            .typeTxt1{
              font-size: 12px;
            }
          }

        }
      }
      .listCon_address{
        padding-top: 10px;
        display: flex;
        justify-content: flex-end;
        >p{
          display: inline-block;
          >span{
            background-color: rgba(229, 229, 229, 1);
            color: rgba(42, 130, 228, 1);
            display: inline-block;
            margin-right: 8px;
            padding: 6px 8px;
            border-radius: 5px;
          }
        }
        .btnActive{
          color: #fff;
          background: linear-gradient(212.76deg, rgba(0, 133, 255, 1) 0%, rgba(48, 67, 255, 1) 100%);
        }
      }
    }
    .blockHeight{
      height: 50px;
    }
  }

  .page{
    height: 45px;
    margin-right: 20px;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
    >button{
      display: inline-block;
      padding: 8px 15px;
      vertical-align: middle;
      background-color: rgba(229, 229, 229, 0.8);
      font-size: 18px;
    }
    >p{
      display: inline-block;
      // color: #fff;
      font-size: 20px;
      margin: 0 20px;
      vertical-align: middle;
      color: rgba(0, 0, 0, 1);
    }
  }
}