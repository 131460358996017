.positionList{
    width: 100%;
    //height: 100%;
    //overflow: hidden;
    height: calc(100% - 80px);
    overflow: auto !important;
    background-color: rgba(255, 255, 255, .5);
    z-index: 9999;
    border-radius: 20px;
    color: #000;
    .swiper{
        text-align: center;
        margin-bottom: 15px;
        position: relative;
        >aside{
            .my_swiper {
                width: 100%;
                height: 160px;
            }

            .my_slide {
                height: 100%;
            }

        }
        .buttonTxt{
            color: #282828 !important;
        }
        .titleTxt{
            font-size: 16px;
            margin: 0 0 5px 28px;
            text-align: left;
            z-index: 20;
        }
        .lastImg{
            position: absolute;
            left: 2vw;
            top: 50%;
            width: 40px;
            height: 40px;
            z-index: 2001;
        }
        .nextImg{
            position: absolute;
            right: 2vw;
            top: 50%;
            width: 40px;
            height: 40px;
        }
        .swpierItem{
            padding: 20px;
            background: #fff;
            border-radius: 12px;
            width: calc(90vw - 96px) !important;
            margin-left: calc(2vw + 48px);
            margin-top: 20px;
            .itemTop{
                display: flex;
                text-align: left;
                .topL{
                    flex: 1;
                    >p{
                        overflow: hidden; /*超出部分隐藏*/
                        white-space: nowrap; /*不换行*/
                        text-overflow: ellipsis;
                        width: calc(90vw - 160px);
                        color: #a6a6a6;
                        margin: 5px 0;
                        >img{
                            width: 30px;
                            height: 30px;
                            vertical-align: middle;
                            display: inline-block;
                        }
                    }
                    >h1{
                        overflow: hidden; /*超出部分隐藏*/
                        white-space: nowrap; /*不换行*/
                        text-overflow: ellipsis;
                        width: calc(90vw - 160px);
                        color: #1F2126;
                        font-size: 16px;
                    }
                }
                .topR{
                    margin-left: 15px;
                    width: 70px;
                    >img{
                        width: 50px;
                        height: 50px;
                        border-radius: 12px;
                    }
                }
            }
            .itemBottom{
                font-size: 14px;
                overflow: hidden; /*超出部分隐藏*/
                white-space: nowrap; /*不换行*/
                text-overflow: ellipsis;
                text-align: left;
                >b{
                    color: rgba(17, 39, 255, 1);
                    font-weight: 500;
                    vertical-align: middle;
                    display: inline;
                }
                >p{
                    display: inline;
                    color: #a6a6a6;
                    vertical-align: middle;
                    margin-left: 10px;
                }
            }
        }
    }
    .choose{
        margin-left: 28px;
        >p{
            font-size: 16px;
            display: inline-block;
            margin-right: 25px;
            margin-top: 5px;
            margin-bottom: 5px;
        }
        .chooseActive{
            font-weight: 600;
        }
    }
    >p{
        height: 30px;
        font-size: 22px;
        text-align: center;
        font-weight: 600;
        margin: 0;
    }
    .topChoose{
        padding-left: 4vw;
        padding-right: 4vw;
        position: fixed;
        z-index: 10;
        background-color: rgba(255, 255, 255, 0.5);
        .topBtn{
           width: 100%;
            margin-bottom: 15px;
            >button{
                width: 20vw;
                color: rgba(17, 39, 255, 1);
                background: #fff;
                border: none;
                border-radius: 20px;
                box-shadow: 0px 3px 23px 7px rgba(138, 199, 255, 0.52);
                margin-right: 4vw;
                &:last-child{
                    margin-right: 0;
                }
            }
            .btnActive{
                color: #fff;
                background: linear-gradient(212.76deg, rgba(0, 133, 255, 1) 0%, rgba(48, 67, 255, 1) 100%);
            }
        }
        .chooseCon{
            text-align: end;
            margin-bottom: 5px;
            .chooseItem{
                display:inline;
                margin-right: 10px;
                >span{
                    display: inline-block;
                    margin-left: 8px;
                    line-height: 19px;
                    vertical-align: middle;
                    width: 0;
                    height: 0;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 5px solid black;
                }
            }
        }

    }

    .listScroll1{
        margin-top: 80px;
    }
    .listScroll{
        //height: calc(100% - 80px);
        //overflow: auto;
        padding: 0 24px;
        box-sizing: border-box;
        .blockHeight{
            height: 80px;
        }
    }
    .list{
        >img{
            margin-top: 15%;
            width: 160px;
            height: 120px;
            margin-left: calc(50% - 80px);
        }
        >span{
            display: block;
            text-align: center;
            font-size: 14px;
            color: #86909C;
            margin-top: 3%;
            padding-bottom: 5%;
        }
        >article{
            margin-bottom: 15px;
            padding: 20px;
            background-color: #fff;
            border: 1px solid #ECEDF2;
            box-sizing: border-box;
            border-radius: 10px;
            box-shadow: 0 2px 12px 3px rgba(227, 227, 227, 0.3);
            position: relative;
            .listCon_title{
                width: 100%;
                //display: flex;
                vertical-align: middle;
                >section{
                    .topTitle{
                        width: calc(100% - 170px);
                        display: inline-block;
                        vertical-align: middle;
                        .headline{
                            font-size: 16px;
                            font-weight: 600;
                            color: #1F2126;
                            vertical-align: middle;
                            margin: 0;
                            display: inline-block;
                            max-width: calc(100% - 40px);
                            //max-width: 70%;
                            overflow: hidden; /*超出部分隐藏*/
                            white-space: nowrap; /*不换行*/
                            text-overflow: ellipsis; /*超出部分文字以...显示*/
                        }
                        >img{
                            width: 25px;
                            height: 25px;
                            vertical-align: middle;
                            display: inline-block;
                        }
                    }
                    .salary{
                        width: 170px;
                        text-align: right;
                        display: inline-block;
                        >b{
                            display: block;
                            color: rgba(17, 39, 255, 1);
                            font-weight: 500;
                            vertical-align: middle;
                            font-size: 14px;
                            max-width: 100%;
                        }
                    }
                }
                >aside{
                    >section{
                        .typeTxt{
                            padding-top: 10px;
                            font-size: 13px;
                            color: #a6a6a6;
                            max-width: 100%;
                            overflow: hidden;
                            white-space: nowrap; /*不换行*/
                            text-overflow: ellipsis;
                            position: relative;
                            >p{
                                display: inline;
                                vertical-align: middle;
                                margin-right: 6px;
                                color: rgba(166, 166, 166, 1);
                            }
                            .listCon_address{
                                display: inline-block;
                                >p{
                                    display: inline-block;
                                    margin: 0;
                                    font-size: 10px;
                                    >span{
                                        background-color: rgba(238, 238, 238, 1);
                                        color: rgba(0, 0, 0, 1);
                                        display: inline-block;
                                        margin-right: 8px;
                                        padding: 4px 8px;
                                        border-radius: 12px;
                                    }
                                }
                            }
                            .companyName{
                                max-width: 80%;
                                overflow: hidden; /*超出部分隐藏*/
                                white-space: nowrap; /*不换行*/
                                text-overflow: ellipsis; /*超出部分文字以...显示*/
                                display: inline-block;
                                margin: 0;
                            }
                            .btn{
                                position: absolute;
                                right: 0;
                                bottom: 0;
                                display: flex;
                                align-items: center;
                                justify-content: flex-end;
                                >button{
                                    background: linear-gradient(212.76deg, rgba(0, 133, 255, 0.87) 0%, rgba(48, 67, 255, 0.87) 100%);
                                    color: #fff;
                                    padding: 5px 13px;
                                    margin: 0;
                                    font-size: 12px;
                                    border-radius: 20px;
                                    white-space: nowrap;
                                    outline: none;
                                    border: none;
                                    &:active{
                                        opacity: 0.5;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .page{
        height: 70px;
        // margin-right: 20px;
        // margin-top: 15px;
        text-align: center;
        // padding: 15px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 -2px 4px rgba(207, 202, 202, 0.2);
        background-color: #fff;
        color: #000;

        >button{
            display: inline-block;
            padding: 8px 15px;
            vertical-align: middle;
            background-color: rgba(229, 229, 229, 0.8);
            font-size: 16px;
            outline: none;
            border: none;
        }
        >p{
            display: inline-block;
            color: #000;
            font-size: 20px;
            margin: 0 20px;
            vertical-align: middle;
        }
    }

    .loadingWrap {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
            .load_container {
                width: 150px;
                text-align: center;
                //padding-top: 20%;
    
                .load {
                    width: 15px;
                    height: 15px;
                    background-color: #bebebe;
    
                    border-radius: 100%;
                    display: inline-block;
                    -webkit-animation: bouncedelay 1.4s infinite ease-in-out;
                    animation: bouncedelay 1.4s infinite ease-in-out;
                    /* Prevent first frame from flickering when animation starts */
                    -webkit-animation-fill-mode: both;
                    animation-fill-mode: both;
                }
    
                .load1 {
                    -webkit-animation-delay: -0.32s;
                    animation-delay: -0.32s;
                }
    
                .load2 {
                    -webkit-animation-delay: -0.16s;
                    animation-delay: -0.16s;
                }
            }
    
            @-webkit-keyframes bouncedelay {
    
                0%,
                80%,
                100% {
                    -webkit-transform: scale(0.0)
                }
    
                40% {
                    -webkit-transform: scale(1.0)
                }
            }
    
            @keyframes bouncedelay {
    
                0%,
                80%,
                100% {
                    transform: scale(0.0);
                    -webkit-transform: scale(0.0);
                }
    
                40% {
                    transform: scale(1.0);
                    -webkit-transform: scale(1.0);
                }
            }
    }
}