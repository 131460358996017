.idCard{
    height: 100vh;
    .top{
        >p{
            font-size: 16px;
            padding: 10px 10px;
            vertical-align: middle;
            text-align: center;
            position: relative;
            margin: 0;
            >i{
                font-size: 22px;
                vertical-align: middle;
                position: absolute;
                left: 15px;
                top: 25px;
            }
            >span{
                font-weight: 500;
                font-size: 25px;
            }
        }
        .back{
            >i{
                color: rgba(56, 56, 56, 1);
            }
        }
    }
    .conH{
        height: calc(100% - 30px);
    }
    .content{
        margin: 0 20px;
        padding: 120px 20px 0;
        text-align: center;
        background: rgba(255, 255, 255, 1);
        border-radius: 20px;
        height: 45%;
        >aside{
            position: relative;
            >img{
                width: 192px;
                height: 138px;
            }
            >i{
                color: #fff;
                background-color: #F5222D;
                width: 35px;
                height: 35px;
                display: flex;
                justify-content: center;
                border-radius: 100%;
                align-items: center;
                position: absolute;
                bottom: 0;
                right: calc(50% - 170px);
            }
        }
        >p{
            margin: 25px 0 100px;
            font-size: 16px;
            color: rgba(0, 0, 0, 1);
        }
        .redP{
            >img{
                width: 15px;
                height: 15px;
                margin-right: 8px;
                vertical-align: middle;
            }
        }
    }
    .bottom_btn {
        text-align: center;
        width: 100%;
        margin-top: 50px;
        >button {
            width: 40%;
            border-radius: 20px;
            color: #fff;
            background: linear-gradient(212.76deg, rgba(0, 133, 255, 0.87) 0%, rgba(48, 67, 255, 0.87) 100%);
            box-shadow: 0px 3px 23px 7px rgba(138, 199, 255, 0.52);
            outline: none;
            &:active{
                opacity: 0.5;
            }
        }
    }
}