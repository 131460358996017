.homeIndex{
  height: 100vh;
  //overflow: hidden;
  .home{
    position: relative;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .dig_con{
      width: 100%;
      height: 100%;
      position: relative;
      .textCon{
        position: absolute;
        bottom: 0;
        left:0;
        width: 100%;
        z-index:2;
        .chatCon {
          margin-left: 4vw;
          width: 92vw;
          height: 100%;
          overflow: auto;
          padding-bottom: 10px;
          &::-webkit-scrollbar { width: 0 !important }
          .chatItem{
            transform: translateX(0px) translateY(0px) translateZ(1px);
            // max-height: 70vh;
            overflow: auto;
            .itemCon{
              margin: 10px 0 15px;
              font-size: 16px;
              overflow: auto;
              .userCon{
                text-align: right;
                .bgBlue{
                  background-color: rgba(15, 38, 254, 0.65);
                  color: #fff;
                }
              }
              .robotCon{
                margin-bottom: 10px;
              }
              .robotItem{
                max-width: 74%;
                text-align: left;
                display: inline-block;
                padding: 20px;
                color: rgba(0, 0, 0, 1);
                background-color: rgba(255, 255, 255, 0.9);
                border-radius: 15px;
                >button{
                  width: 100%;
                  font-size: 16px;
                  margin: 10px 0;
                  padding: 5px;
                  border-radius: 30px;
                  color: rgba(24, 51, 229, 1);
                  border: 1px solid rgba(15, 38, 254, 0.3);
                }
              }
            }
          }
        }
        .btn{
          // padding-right: 15%;
          padding-bottom: 20px;
          background: linear-gradient(180deg, rgba(218, 225, 239, 0) 0%, rgba(225, 230, 243, 1) 99.92%);
          .chooseChat{
            background: rgba(255, 255, 255, 0.9);
            width: calc(88vw - 72px);
            margin-left: 4vw;
            margin-bottom: 20px;
            border-radius: 15px;
            padding: 20px 20px 10px;
            font-size: 16px;
            display: flex;
            flex-direction: column; /* 垂直排列 */
            >p{
              margin-bottom: 20px;
            }
            .itemButton{
              padding: 8px 0;
              border-radius: 40px;
              border: 1px solid rgba(15, 38, 254, 0.3);
              background-color: #fff;
              margin-bottom: 15px;
              font-size: 15px;
              color: rgba(24, 51, 229, 1);
            }
          }
          .speakBtn{
            width: 92vw;
            margin-left: 4vw;
            display: flex;
            padding-bottom: 20px;
            -webkit-touch-callout:none;
            -webkit-user-select:none;
            -khtml-user-select:none;
            -moz-user-select:none;
            -ms-user-select:none;
            user-select:none;
            >button{
              flex: 1;
              padding: 12px 0;
              border-radius: 90px;
              position: relative;
              >img{
                width: 20px;
                height: 20px;
                position: absolute;
                top: calc(50% - 10px);
                left: calc(4vw + 10px);
              }
            }
            >aside{
              width: 32px;
              margin-left: 4vw;
              display: flex;
              align-items: center;
              justify-content: center;
              >i{
                font-size: 16px;
                background-color: #fff;
                border-radius: 50%;
                padding: 8px 12px;
              }
            }
          }
          .checkBtn{
            display: block;
            width: 100vw;
            .btnItem{
              width: 20vw;
              display: inline-block;
              text-align: center;
              >img{
                width: 25px;
                height: 25px;
                background-color: #fff;
                border-radius: 50%;
                padding: 10px;
              }
              >span{
                display: block;
                margin-top: 5px;
              }
            }
            .btnOne{
              margin-left: 4vw;
            }
            .btnTwo{
              margin: 0 4vw;
            }
            .btnThree{
              margin-right: 4vw;
            }
          }
          .chooseBtn{
            color: rgba(51, 51, 51, 1);
            width: 92vw;
            margin-left: 4vw;
            >p{
              margin: 0 0 15px;
            }
            >aside{
              background: rgba(255, 255, 255, 0.76);
              padding: 10px 20px;
              border-radius: 30px;
              margin-bottom: 15px;
            }
          }
        }
      }
      .nextcon{
        width: 100%;
        height: 100%;
        padding-top: 7%;
        position: relative;
        .nextcas{
          width: 100%;
          height:calc(100% - 240px);
          margin-bottom: 160px;
          margin-top: 80px;
        }
        .notMove{
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .slide-fade-enter-active {
    transition: all 0.5s ease;
  }
  .slide-fade-leave-active{
    transition: all .2s ease;
  }
  .slide-fade-enter, .slide-fade-leave-to {
    // transform: translateY(100%);
    opacity: 0;
  }


  .popContent{
    width: 90vw;
    background-color: rgba(229, 229, 229, 0.8);
    padding: 10px 20px;
    position: relative;
    border-radius: 10px;
  }
  /deep/.u-mode-center-box{
    border-radius: 10px;
    background-color: rgba(229, 229, 229, 0.3);
  }
  /deep/.u-popup__open {
    overflow: auto !important;
  }
  /deep/.uicon-close{
    font-size: 18px !important;
  }
  /deep/.u-drawer-center{
    bottom: -22vh;
  }
  /deep/.u-close--top-right{
    top: 20px;
    right: 20px;
  }
}
