.interviewResult{
  height: 600px;
  overflow: auto;
  padding: 20px;
  .top{
    position: relative;
    padding-top: 40px;
    >aside{
      width: calc(100% - 170px);
      vertical-align: middle;
      .avatar{
        display: inline-block;
        vertical-align: middle;

        >img{
          width: 77px;
          height: 77px;
          display: inline-block;
          border-radius: 50%;
        }
      }
      .inform{
        vertical-align: middle;
        padding-left: 10px;
        display: inline-block;
      }
    }
    .mark{
      position: absolute;
      right: 0;
      top: 0;
      >section{
        position: relative;
        >img{
          width: 170px;
          height: 170px;
        }
        >p{
          position: absolute;
          right: 83px;
          top: 60px;
          margin: 0;
          font-size: 22px;
          font-weight: 600;
          color: #0670FE;
        }
      }
    }
  }
  .comment{
    padding-top: 20px;
    font-size: 16px;
    margin-bottom: 40px;
    >h5{
      font-weight: 500;
      color: rgba(0, 109, 255, 1);
      font-size: 18px;
      margin-bottom: 20px;
    }
    >p{
      color: rgba(102, 102, 102, 1);
    }
  }
  .grade{
    display: flex;
    border-radius: 4px;
    background: rgba(240, 247, 255, 1);
    padding: 24px;
    margin: 30px 0;
    .remark{
      flex: 1;
      position: relative;
      &::after{
        position: absolute;
        content: '';
        width: 3px;
        height: 100%;
        right: 10px;
        top: 0;
        background: rgba(227, 233, 245, 1);
      }
      .btn{
        background: rgba(0, 109, 255, 1);
        color: #ffffff;
        font-size: 16px;
        padding: 12px 20px;
        height: auto;
        border: none;
        border-radius: 5px;
      }
      >p{
        font-size: 32px;
        margin: 20px 0;
        color: rgba(161, 167, 196, 1);
        >span{
          color: rgba(0, 109, 255, 1);
        }
      }
    }
    .txt{
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      >p{
        font-size: 16px;
        padding-left: 20px;
      }
    }
  }
}