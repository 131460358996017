.areaWrap {
    // width: 100%;
    height: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    
    .list {
        height: 80%;
        display: flex;
        justify-content: center;
        overflow: hidden;
        border-radius: 20px 20px 0 0;

        .areaList {
            list-style: none;
            width: 100%;
            margin: 0;
            padding: 0;
            overflow-y: auto;
            overflow-x: hidden;
        }

        .item {
            height: 50px;
            display: flex;
            justify-content: start;
            align-items: center;
            padding-left: 10px;
            box-sizing: border-box;
        }
        
        .province_list {
            width: 26%;
            background-color: #F8F8F9;
            

            .province_active {
                background-color: #fff;
            }
        }
    
        .city_list {
            width: 37%;
            border-right: 1px solid #bbb;
            background-color: #fff;

            .city_active {
                color: #00BAFF;
            }
        }
    
        .county_list {
            width: 37%;
            background-color: #fff;

            .city_county {
                color: #00BAFF;
            }
        }
    }
    
    .bottom {
        width: 100%;
        height: 8%;
        background-color: #fff;
        box-shadow: 0 -3px 12px 3px rgba(227, 227, 227, 0.1);
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        border-radius: 0 0 20px 20px;

        .cleanBtn {
            width: 25%;
            color: #666;
            background-color: #eee;
            border: none;
            height: 35px;
            border-radius: 8px;
        }

        .confirmBtn {
            width: 50%;
            color: #fff;
            border: none;
            background: linear-gradient(150.26deg, rgba(65, 181, 248, 1) 0%, rgba(0, 225, 233, 1) 100%);;
            height: 35px;
            border-radius: 8px;
        }
    }
}