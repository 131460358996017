.signResult{
  width: 100%;
  height: 80vh;
  >aside{
    margin: 30px 20px;
    padding: 15% 20px;
    background-color: rgba(255, 255, 255, 0.83);
    border-radius: 20px;
    height: 52%;
    .inform{
      margin: 20px;
      text-align: center;
      >img{
        width: 80px;
        height: 80px;
      }
      >p{
        margin-top: 20px;
        font-size: 20px;
        color: black;
      }
    }
    .lose{
      >i{
        background-color: rgba(227, 60, 100, 1);
      }
    }
    .person{
      text-align: center;
      >aside{
        color: rgba(102, 102, 102, 1);
        position: relative;
        padding-top: 30px;
        margin: 15px 0 35px;
        &::before{
          content: '';
          width: 100px;
          height: 1px;
          border-bottom: 0.5px solid rgba(204, 204, 204, 1);
          position: absolute;
          left: 10vw;
          top: 40px;
          vertical-align: middle;
        }
        &::after{
          content: '';
          width: 100px;
          height: 1px;
          border-bottom: 0.5px solid rgba(204, 204, 204, 1);
          position: absolute;
          right: 10vw;
          top: 40px;
          vertical-align: middle;
        }
      }
      >p{
        font-size: 14px;
        margin: 0;
        color: black;
        >span{
          color: rgba(16, 38, 254, 1);
        }
      }
      .numTxt{
        width: 80vw;
        margin-left: 10vw;
        text-align: left !important;
        margin-bottom: 8px;
        >b{
          font-weight: 500;
          font-size: 13px;
          display: inline-block;
          margin-right: 10px;
          color: #fff;
          width: 20px;
          height: 20px;
          text-align: center;
          border-radius: 50%;
          background: linear-gradient(212.76deg, rgba(0, 133, 255, 0.87) 0%, rgba(48, 67, 255, 0.87) 100%);
        }
      }
    }
  }
  .btn{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(180deg, rgba(251, 253, 255, 0) 0%, rgba(255, 255, 255, 0.78) 38.04%, rgba(245, 251, 255, 1) 100%);
    >aside{
      width: 92vw;
      margin-left: 4vw;
      padding: 23px 0;
      >button{
        display: inline-block;
        width: 45%;
        padding: 10px 0;
        border-radius: 80px;
        &:active{
          opacity: 0.5;
        }
      }
      .check{
        margin-right: 5%;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 23px 7px rgba(212, 212, 212, 0.28);
      }
      .over{
        margin-left: 5%;
        background: linear-gradient(212.76deg, rgba(0, 133, 255, 1) 0%, rgba(48, 67, 255, 1) 100%);
        box-shadow: 0px 3px 23px 7px rgba(138, 199, 255, 0.52);
        color: #fff;
      }
    }
  }
}