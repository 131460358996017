*:focus {
  outline: none;
}
button:focus,
a:focus {
  outline: none;
}
a, button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.mask {
  width: 100vw;
  height: 100vh;
  background-color: #666;
  position: fixed;
  inset: 0;
  z-index: 1001;
}