.screenWrap {
    height: 100%;
    padding: 0 20px;
    box-sizing: border-box;

    .list {
        height: 80%;
        background-color: #fff;
        overflow-y: auto;
        overflow-x: hidden;
        border-radius: 20px 20px 0 0;

        .selector {
            padding: 12px;
            box-sizing: border-box;
    
            .screenHeadline {
                font-size: 15px;
                font-weight: 600;
                color: #222222;
                margin-bottom: 12px;
            }
    
            .contentWrap {
                width: 100%;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                flex-wrap: wrap;
                
                .item {
                    width: 30%;
                    margin-right: 3%;
                    border-radius: 6px;
                    background-color: #eee;
                    font-size: 12px;
                    font-weight: 500;
                    color: #222222;
                    text-align: center;
                    padding: 9px 12px;
                    box-sizing: border-box;
                    margin-bottom: 12px;
                }
    
                .active {
                    border: 1px solid #00BAFF;
                    background: #E6F8FF;
                    color: #00BAFF;
                }
            }
        }
    }

    .bottom {
        width: 100%;
        height: 8%;
        background-color: #fff;
        box-shadow: 0 -3px 12px 3px rgba(227, 227, 227, 0.1);
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        border-radius: 0 0 20px 20px;

        .cleanBtn {
            width: 25%;
            color: #666;
            background-color: #eee;
            border: none;
            height: 35px;
            border-radius: 8px;
        }

        .confirmBtn {
            width: 50%;
            color: #fff;
            border: none;
            background: linear-gradient(150.26deg, rgba(65, 181, 248, 1) 0%, rgba(0, 225, 233, 1) 100%);;
            height: 35px;
            border-radius: 8px;
        }
    }
}