
.level {
  // z-index: 1000;
  // height: 100vh;
  position: relative;
  .logo{
    position: absolute;
    left:4vw;
    top: 2vh;
    width: calc(100% - 30vw);
    >img{
      width: 40px;
      height: 40px;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
    }
    >p{
      display: inline-block;
      color: rgba(15, 38, 254, 1);
      font-size: 17px;
      vertical-align: middle;
    }
  }
  .back{
    position: absolute;
    top: 2vh;
    right: 4vw;
    z-index: 30001;
    >button{
      font-size: 14px;
      padding: 10px 8px;
      background: rgba(255, 255, 255, 0.5);
      color: rgba(81, 81, 81, 1);
      border-radius: 50px;
      vertical-align: middle;
      >img{
        width: 20px;
        height: 20px;
        vertical-align: middle;
      }
      >p{
        display: inline-block;
        margin: 0 10px;
        vertical-align: middle;
      }
      &:active{
        opacity: 0.5;
      }
    }
    .redBg{
      background: rgba(255, 88, 44, 0.8);
      color: #fff;
    }
  }
  .newest{
    position: absolute;
    top: 30vh;
    right: 4vw;
    width: 60%;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    >aside{
      margin-bottom: 15px;
      .chatNewCon{
        display: inline-block;
        padding: 15px 10px;
        border-radius: 10px;
        margin: 0;
        line-height: 0.7;
        >span{
          display: inline-block;
          line-height: 20px;
          overflow: auto;
        }
      }
    }
    .userCon{
      .chatNewCon{
        background-color: rgba(15, 38, 254, 0.6);
        >span{
          max-height: 40px;
          color: rgba(255, 255, 255, 1);
        }
      }
    }
    .robotCon{
      .chatNewCon{
        background-color: rgba(255, 255, 255, 0.75);
        >span{
          max-height: 60px;
          color: rgba(26, 26, 26, 1);
        }
      }
    }
  }
  .chatList{
    position: absolute;
    top: 20vh;
    right: 4vw;
    width: 50%;
    height: 400px;
    overflow: auto;
    font-size: 13px;
    display: flex;
    flex-direction: column;
    //justify-content: flex-end;
    align-items: flex-end;
    >div{
      >aside{
        margin-bottom: 15px;
        .chatListCon{
          display: inline-block;
          padding: 15px 10px;
          margin: 0;
          line-height: 0.7;
          >span{
            display: inline-block;
            line-height: 20px;
            overflow: auto;
            word-break: break-word;
          }
          >button{
            width: 100%;
            color: rgba(17, 38, 255, 1);
            border: 1px solid rgba(16, 38, 254, 1);
            border-radius: 25px;
            margin: 10px 0;
            background: #fff;
          }
        }
      }
      .userCon{
        .chatListCon{
          background: linear-gradient(212.76deg, rgba(0, 133, 255, 0.6) 0%, rgba(48, 67, 255, 0.6) 100%);
          border-radius: 15px 15px 0px 15px;
          >span{
            color: rgba(255, 255, 255, 1);
          }
        }
      }
      .robotCon{
        .chatListCon{
          border-radius: 15px 15px 15px 0px;
          background-color: rgba(255, 255, 255, 0.75);
          >span{
            color: rgba(26, 26, 26, 1);
          }
        }
      }
    }

  }
  .downImg{
    position: absolute;
    top: calc(20vh + 400px);
    right: 4vw;
    width: 30px;
    height: 30px;
  }
  .checkBtn{
    position: fixed;
    left: 0;
    bottom: 75px;
    width: 100%;
    padding-bottom: 30px;
    background: linear-gradient(180deg, rgba(251, 253, 255, 0) 0%, rgba(245, 251, 255, 1) 100%);
    .btnItemW{
      width: 28vw;
      >button{
        >span{
          display: block;
          font-size: 18px;
        }
        >p{
          margin: 8px 0;
          font-size: 14px;
        }
      }
    }
    .btnItemW2{
      width: 20vw;
      >button{
        >span{
          display: block;
          font-size: 16px;
        }
        >p{
          margin: 8px 0;
          font-size: 12px;
        }
      }
    }
    .btnItem{
      display: inline-block;
      >button{
        //background-color: rgba(15, 38, 254, .8);
        background: linear-gradient(212.76deg, rgba(0, 133, 255, 0.87) 0%, rgba(48, 67, 255, 0.87) 100%);
        outline: none;
        color: #fff;
        border-radius: 15px;
        font-size: 15px;
        padding: 10px 15px;
        width: 100%;
        text-align: left;
        box-shadow: 0px 3px 23px 7px rgba(138, 199, 255, 0.52);
        &:active{
          background: linear-gradient(212.76deg, rgba(0, 133, 255, 0.5) 0%, rgba(48, 67, 255, 0.5) 100%);

        }
      }
    }
    .btnOne{
      margin-left: 4vw;
    }
    .btnTwo{
      margin: 0 4vw;
    }
    .btnThree{
      margin-right: 4vw;
    }
  }
  .btn{
    position: fixed;
    left:0;
    bottom: 0;
    width: 100vw;
    height: 70px;
    display: flex;
    //border-top: 1px solid rgba(216, 216, 216, 1);
    padding: 8px 0;
    background: rgba(245, 251, 255, 1);
    z-index: 5000;
    overflow: hidden;
    .btnLeft{
      width: 75px;
      padding-left: 8px;
      display: flex;
      align-items: center;
      >aside{
        width: 60px;
        height: 60px;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        >img{
          width: 60px;
          height: 60px;
          object-fit: cover;
        }
        &:active{
          opacity: 0.5;
        }
      }
      .stopTalk{
        width: 65px;
        height: 60px;
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        >img{
          width: 50px;
          height: 50px;
        }
        &:active{
          opacity: 0.5;
        }
      }
      >article{
        display: inline-block;
        //background: url('../../assets/img/home/voicebg2.png');
        //background-size: cover;
        width: 55px;
        height: 50px;
        padding: 5px;
        border-radius: 50%;
        margin-left: 10px;
        >img{
          width: 50px;
          height: 50px;
        }
        &:active{
          opacity: 0.5;
        }
        .loadingWrapper {
          width: 30px;
          height: 30px;
          margin: 5px auto;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .loadingBar,.loadingBar:before,.loadingBar:after {
          width: 0.3rem;
          height: 1rem;
          background-color: #fff;
          animation: animateBar var(--animation-duration, 1s)  ease-in-out infinite;
         border-radius: 3px;
        }
        .loadingBar {
          animation-delay: -.16s;
          position: relative;
          display: flex;
          align-items: center;
        }
        .loadingBar:before {
          position: absolute;
          right: 0.8rem;
          content: '';
        }
        .loadingBar:after {
          position: absolute;
          animation-delay: -.32s;
          content: '';
          left: 0.8rem;
        }
        @keyframes animateBar {
          0% {
            height: 0.8rem;
          }
          50% {
            height: 1.5rem
          }
          100% {
            height: 0.8rem
          }
        }
      }
    }
    .btnRight{
      flex: 1;
      vertical-align: middle;
      font-size: 18px;
      padding-left: 10px;
      padding-right: 20px;
      >p{
        color: rgba(119, 119, 119, 1);
        vertical-align: middle;
        font-size: 15px;
        margin-top: 26px
      }
      .swiperList{
        overflow: hidden;
        .swiper{
          height: 50px;
          margin-top: 12px;
          .tospeak{
            color: rgba(0, 0, 0, 1);
            margin: 14px 0;
          }
          .example{
            color: rgba(119, 119, 119, 1);
            font-size: 15px;
          }
          .str{
            font-size: 15px;
            margin-top: 0;
            color: rgba(0, 0, 0, 1);
          }
        }
      }
      >section{
        height: 70px;
        overflow: auto;
        display: flex;
        align-items: center;
        font-size: 15px;
      }
      .signWay{
        width: calc(95vw - 85px);
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        justify-items: center;
        .txt{
          width: 26%;
          text-align: left;
          margin-left: -1vw;
        }
        >aside{
           //flex: 1;
          width: 30%;
          text-align: center;
          .tospeak1{
            width: 90%;
            font-size: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          >button{
            width: 100%;
            border-radius: 6px;
            color: #fff;
            font-size: 12px;
            padding: 14px 0;
            vertical-align: middle;
            background-color: rgba(254, 124, 15, 1);
            outline: none;
            border: none;
            >img{
              vertical-align: middle;
            }
            &:active{
              opacity: 0.5;
            }
          }
          .Alipay{
            padding: 11px 0;
            background: linear-gradient(212.76deg, rgba(0, 133, 255, 0.87) 0%, rgba(48, 67, 255, 0.87) 100%);
            >img{
              width: 20px;
              height: 20px;
              margin-right: 5px;
            }
          }
          .identity{
            background: linear-gradient(212.76deg, rgba(48, 184, 255, 0.87) 0%, rgba(0, 168, 255, 0.87) 100%);
            padding: 6px 3px;
            >img{
              width: 30px;
              height: 30px;
              //margin-bottom: 4px;
            }
          }
        }
      }
      .getWork{
        color: #fff;
        background: linear-gradient(212.76deg, rgba(0, 133, 255, 1) 0%, rgba(48, 67, 255, 1) 100%);
        box-shadow: 0px 3px 23px 7px rgba(138, 199, 255, 0.52);
        font-size: 14px;
        border-radius: 40px;
        margin-top: 11px;
        padding: 10px 70px;
        margin-left: calc(50% - 100px);
        &:active{
          opacity: 0.5;
        }
      }
    }
  }
  .bottom_wrap {
    position: fixed;
    bottom: 0;
    z-index: 1000;
  }
  .btnBg{
    background: linear-gradient(180deg, rgba(251, 253, 255, 0) 0%, rgba(255, 255, 255, 0.78) 38.04%, rgba(245, 251, 255, 1) 100%);
  }
  .btnBgColor {
    background-color: #f9f9f9;
    border: none;
    outline: none;
  }
  
}
.goBack {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  color: #000;
  margin-left: 4vw;
  >i {
    font-size: 25px;
    position: absolute;
    top: -20px;
    &::before{
      border-radius: 50%;
      padding: 5px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0.09%, rgba(248, 249, 255, 1) 100%);
    }
  }
}

.loadingWrap {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

:global{
  .ant-modal-root {
    // z-index: 5000 !important;
    position: absolute;
    top: 10vh;
  }
  .ant-modal-body {
    height: 100vh;
  }
  .ant-modal {
    position: absolute;
    max-width: 100vw !important;
  }

  .weipay {
    .ant-modal-content {
      // z-index: 5000;
      background-color: transparent !important; /* 将背景设置为透明 */
      padding: 0;
      box-sizing: border-box;
      border-radius: 20px;

      .ant-modal-close {
        position: absolute;
        right: 20px;
        top: -20px;
        border-radius: 50%;
        z-index: 1000;
        background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0.09%, rgba(248, 249, 255, 1) 100%);
        //background-color: transparent !important; /* 将关闭按钮的背景设置为透明 */
        outline: none;
        border: none;
        width: 40px;
        height: 40px;
        .ant-modal-close-x {
          margin-left: -6px;
          margin-top: 2px;
          display: block;
          font-size: 20px;
          color: #000;
        }
      }
      .ant-modal-body {
        height: 65vh;
        overflow: hidden;
        >div {
          height: 100%;
          overflow: hidden;
        }
      }
    }
  }
  .overHeight{
    .ant-modal-body {
      height: 80vh !important;
    }
  }
}