.verify{
  height: 80vh;
  //background: rgba(255,255,255,0.83);
  >h1{
    font-weight: 500;
    padding: 15px 0 0;
    text-align: center;
    color: rgba(0, 0, 0, 1);
    font-size: 18px;
    margin: 0;
  }
  .inform{
    overflow: auto;
    background-color: #fff;
    border-radius: 12px;
    width: 90%;
    margin: 20px 5%;
    padding: 15px 0;
    box-shadow: 0px 2px 12px 3px rgba(227, 227, 227, 0.5);
    >aside{
      padding: 0 15px;
      font-size: 16px;
      >span{
        color:rgba(164, 164, 164, 1);
        display: inline-block;
        margin: 5px 0;
        width: 60px;
      }
      >p{
        color: rgba(0, 0, 0, 1);
        display: inline-block;
        margin: 5px 0;
        text-align: left;

      }
    }
    >section{
      margin: 0 15px;
      padding: 10px 0 15px;
      border-bottom: 1px solid rgba(204, 204, 204, 1);
      display: flex;
      justify-content: flex-end;
      &:last-child{
        border-bottom: none;
      }
      >p{
        width: 60px;
        font-size: 15px;
        color: rgba(164, 164, 164, 1) ;
        margin: 8px 0;
      }
      >article{
        width: calc(100% - 60px);
        display: flex;
        //justify-content: flex-end;
        align-items: center;
        position: relative;
        >input{
          padding: 10px 5px;
          font-size: 14px;
          width: 100%;
        }
        .informR{
          color: rgba(0, 0, 0, 1);
          margin: 8px 0;
          >span{
            color: rgba(153, 153, 153, 1);
          }
          .error{
            display: inline-block;
            position: absolute;
            right: 0;
            vertical-align: middle;
            >img{
              display: inline-block;
              width: 15px;
              height: 15px;
              margin: 0 5px 0 12px;
              vertical-align: middle;
            }
            >p{
              display: inline-block;
              color: rgba(254, 112, 15, 1);
              margin: 0;
              vertical-align: middle;
            }
          }
        }
        .countDown{
          color: rgba(16, 38, 254, 1);
          font-size: 14px;
          margin-left: 10px;
          display: inline-block;
          width: 70px;
          position: absolute;
          right: 0;
        }
        .loadingBtn{
          margin-left: calc(50% - 9px);
          padding-top: 1px;
        }
        .code{
          max-width: calc(100% - 90px);
        }
        >button{
          margin-left: 10px;
          display: inline-block;
          width: 70px;
          font-size: 12px;
          background-color: #fff;
          color: rgba(16, 38, 254, 1);
          border: 1px solid rgba(16, 38, 254, 1);
          vertical-align: middle;
          padding: 8px 3px;
          border-radius: 20px;
          position: absolute;
          right: 0;
          &:active{
            opacity: 0.5;
          }
        }
      }
    }
    >h1{
      font-weight: 500;
      padding: 0 15px;
      font-size: 15px;
      color: rgba(0, 0, 0, 1);
    }
    .position{
      margin-top: 15px;
      padding: 0 15px;
      >section{
        display: flex;
        .name{
          font-size: 18px;
          max-width: 400px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: rgba(0, 0, 0, 1);
        }
        .money{
          flex: 1;
          font-size: 18px;
          vertical-align: middle;
          text-align: right;
          color: rgba(16, 38, 254, 1);
        }
      }
      .listType{
        margin-top: 5px;
        >p{
          display: inline-block;
          >span{
            background-color: rgba(254, 112, 15, 0.21);
            color: rgba(0, 0, 0, 1);
            display: inline-block;
            margin-right: 8px;
            padding: 6px 8px;
            border-radius: 15px;
            font-size: 12px;
          }
        }
      }
      .company{
        margin: 20px 0;
        display: flex;
        >img{
          width: 60px;
          height: 60px;
          margin-right: 20px;
        }
        .companyInform{
          flex: 1;
          >h2{
            font-size: 14px;
            color: rgba(0, 0, 0, 1);
            margin: 0;
          }
          >p{
            color: rgba(81, 81, 81, 1);
            font-size: 12px;
            margin: 5px 0;
          }
        }
      }
    }
  }
  .btn{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(180deg, rgba(251, 253, 255, 0) 0%, rgba(255, 255, 255, 0.78) 38.04%, rgba(245, 251, 255, 1) 100%);
    >aside {
      width: 92vw;
      margin-left: 4vw;
      padding: 23px 0;
      >button{
        display: inline-block;
        width: 45%;
        padding: 15px 0;
        border-radius: 80px;
        &:active{
          opacity: 0.5;
        }
      }
      .check{
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 23px 7px rgba(212, 212, 212, 0.28);
        margin-right: 5%;
      }
      .grayCol{
        background: linear-gradient(212.76deg, rgba(0, 133, 255, 0.52) 0%, rgba(48, 67, 255, 0.52) 100%);
        box-shadow: 0px 3px 23px 7px rgba(138, 199, 255, 0.52);
        margin-left: 5%;
        color: #fff;
        border: none;
      }
      .over{
        margin-left: 5%;
        background: linear-gradient(212.76deg, rgba(0, 133, 255, 0.87) 0%, rgba(48, 67, 255, 0.87) 100%);
        box-shadow: 0px 3px 23px 7px rgba(138, 199, 255, 0.52);
        color: #fff;
      }
    }
  }
}
:global{
  .keypad-content-key-item{
    height: 2rem !important;
    line-height: 2rem !important;
  }
}