.detailHeight{
    height: 100%;
    width: 100%;
}
.detail{
    width: 100%;
    // height: 100%;
    // overflow: hidden;
    height: calc(100% - 40px);
    overflow: auto;
    padding: 0 24px;
    box-sizing: border-box;
    color: #000;
    .box {
        border-radius: 5px;
        background-color: #fff;
        padding: 15px;
        margin-bottom: 15px;
        // 职位详情
        .header {
            display: flex;
            vertical-align: middle;
            padding-bottom: 15px;
            border-bottom: 1px solid rgba(229, 229, 229, 1);
            // 职位名称
            .title {
                font-size: 20px;
                font-weight: 600;
                vertical-align: middle;
                max-width: 80%;
                overflow: hidden;
                white-space: nowrap; /*不换行*/
                text-overflow: ellipsis; /*超出部分文字以...显示*/
            }
            .urgent{
                width: 30px;
                height: 30px;
                margin-left: 6px;
                vertical-align: middle;
            }
            .money {
                font-size: 16px;
                text-align: right;
                color: rgba(17, 39, 255, 1);
                flex: 1;

            }
        }
        >article{
            padding-bottom: 20px;
            .str {
                padding-top: 5px;
                margin: 10px 0;
                font-size: 14px;
                color: #4E5969;
                vertical-align: middle;
                .strCompany{
                    display: inline-block;
                    width: 120px;
                    word-break: break-word;
                    overflow: hidden; /*超出部分隐藏*/
                    white-space: nowrap; /*不换行*/
                    text-overflow: ellipsis; /*超出部分文字以...显示*/
                    vertical-align: middle;
                }
                >b{
                    // display: inline-block;
                    vertical-align: middle;
                    font-weight: normal;
                    margin-right: 3px;
                    >i {
                        margin-right: 8px;
                    }
                }
                i {
                    margin-right: 5px;
                }
            }
            .btn{
                display: flex;
                .signUp{
                    flex: 1;
                    >button{
                        background-color: rgba(255, 141, 26, 1);
                        color: #fff;
                        padding: 10px 20px;
                        margin: 0;
                        font-size: 16px;
                        width: 70%;
                    }
                }
                .inform{
                    flex: 2;
                    font-size: 18px;
                    text-align: right;
                    position: relative;
                    >p{
                        display: inline-block;
                        margin-right: 15px;
                        vertical-align: middle;
                        margin-top: 14px;
                        >i{
                            font-size: 22px;
                            vertical-align: middle;
                            margin-right: 5px;
                        }
                    }
                    .code{
                        position: absolute;
                        top: 45px;
                        right: 0;
                    }
                }
            }
        }
    }
    .question{
        border-radius: 5px;
        background-color: #fff;
        padding: 15px;
        margin-top: 15px;
        margin-bottom: 15px;
        >h1{
            font-size: 16px;
            font-weight: 500;
            margin: 0 20px 20px 0;
        }
        >button{
            background: #fff;
            color: #1127ff;
            border-color: #1127ff;
            margin: 0 8px 8px 0;
            padding: 8px;
        }
    }
    .tap_content {
        background-color: #fff;
        padding: 15px 20px;
        border-radius: 5px;
        overflow: auto;
        // height: 30vh;
        // 工人详情
        .box1 {
            margin-bottom: 15px;
            &:last_child{
                border-bottom: none;
            }
            .main_title {
                font-size: 18px;
                margin-bottom: 20px;
                font-weight: 600;
            }

            .card {
                margin-bottom: 20px;
                font-size: 14px;
                color: #4E5969;
                // display: flex;
                // justify-content: flex-start;
                // align-items: center;
                .title {
                    white-space: nowrap;
                    // color: #1F2126;
                    display: inline;
                }
                .content {
                    // color: #1F2126;
                    margin-left: 7px;
                    display: inline;
                }
                
            }
            .detailTxt{
                // width: 50%;
                // display: inline-block;
            }
            .broBottom{
                border-bottom: solid 1px #F0F0F0;
            }
            .card_2 {
                margin-top: 15px;
                .title {
                    font-size: 18px;
                    font-weight: 600;
                    margin-bottom: 20px;
                }
                
                .text {
                    color: #4E5969;
                    font-size: 14px;
                    line-height: 26px;
                    white-space: pre-line;
                    padding-bottom: 15px;
                    overflow: hidden;
                }
            }
        }
        .blockHeight{
            height: 160px;
        }
    }
    .send{
        position: sticky;
        bottom: 0;
        left: 0;
        width: 100%;
        >button{
            background-color: #00143D;
            color: #fff;
            font-size: 20px;
            padding: 8px 0;
        }
    }
}
.loading{
    text-align: center;
    height: 100%;
    margin-top: 30%;
}