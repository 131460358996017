/* app.css，来自于官网 */
.alert-enter {
    transform: translateX(100%);
}
.alert-enter-active {
    transform: translateX(0);
    transition: transform 300ms;
}
.alert-exit {
}
.alert-exit-active {
    transform: translateX(100%);
    transition: transform 300ms;
}

.alert-exit-done {
    display: none;
}
