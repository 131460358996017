
.positionWrap {
    height: 100%;
}
.detailHeight{
    height: 100%;
    width: 100%;
}
.detail{
    width: 100%;
    height: calc(100% - 40px);
    padding: 0 24px;
    box-sizing: border-box;
    overflow: auto;
    color: #000;
    >aside{
        border-radius: 5px;
        background-color: #fff;
        padding: 15px;
        .header {
            display: flex;
            .conTitle_left{
                width: 65%;
                >h1{
                    font-size: 20px;
                    font-weight: 600;
                    display: inline-block;
                    margin: 0 20px 0 0;
                    max-width: calc(100% - 40px);
                    word-break: break-all;
                    vertical-align: middle;
                } 
                .urgent{
                    display: inline-block;
                    vertical-align: middle;
                    width: 30px;
                    height: 30px;
                }
            }
            .conTitle_right{
                flex: 1;
                >span{
                    display: block;
                    font-size: 16px;
                    text-align: right;
                    color: rgba(17, 39, 255, 1);
                    >b{
                        display: inline-block;
                        font-weight:500;
                    }
                }
            }
        }
        >section{
            margin: 10px 0;
            border-bottom: 1px solid rgba(229, 229, 229, 1);
            >p{
                display: inline-block;
                margin: 0 0 8px;
                font-size: 12px;
                >span{
                    background-color: rgba(238, 238, 238, 1);
                    color: rgba(0, 0, 0, 1);
                    display: inline-block;
                    margin-right: 8px;
                    padding: 4px 8px;
                    border-radius: 13px;
                }
            }
        }
        // 公司招聘详情
        .content {
            // padding-left: 8px;
            margin-top: 10px;
            font-size: 14px;
            color: #727272;
            padding-bottom: 8px;
            .str {
                padding-top: 5px;
                margin: 10px 0;
                font-size: 14px;
                color: #515151;
                vertical-align: middle;
                .strCompany{
                    display: inline-block;
                    width: 120px;
                    word-break: break-word;
                    overflow: hidden; /*超出部分隐藏*/
                    white-space: nowrap; /*不换行*/
                    text-overflow: ellipsis; /*超出部分文字以...显示*/
                    vertical-align: middle;
                }
                >b{
                    vertical-align: middle;
                    // display: inline-block;
                    font-weight: normal;
                    margin-right: 4px;
                    >i {
                        margin-right: 8px;
                    }
                }
                >i{
                    margin-right: 5px;
                }
            }
            .btn{
                display: flex;
                .signUp{
                    flex: 1;
                    >button{
                        background-color: rgba(255, 141, 26, 1);
                        color: #fff;
                        padding: 10px 20px;
                        margin: 0;
                        font-size: 16px;
                        width: 70%;
                    }
                }
                .inform{
                    flex: 2;
                    font-size: 18px;
                    text-align: right;
                    position: relative;
                    >p{
                        display: inline-block;
                        margin-right: 15px;
                        vertical-align: middle;
                        margin-top: 14px;
                        >i{
                            font-size: 22px;
                            vertical-align: middle;
                            margin-right: 5px;
                        }
                    }
                    .code{
                        position: absolute;
                        top: 45px;
                        right: 0;
                    }
                }
            }
        }
    }
    .question{
        border-radius: 5px;
        background-color: #fff;
        padding: 15px;
        margin-top: 15px;
        >h1{
            font-size: 16px;
            font-weight: 500;
            margin: 0 20px 20px 0;
        }
        >button{
            background: #fff;
            color: #1127ff;
            border-color: #1127ff;
            margin: 0 8px 8px 0;
            padding: 8px;
        }
    }
    .conHeight{
        overflow: auto;
        // height: 100%;
        // min-height: 16vh;
        // max-height: 24vh;
        // height: 30vh;
        border-radius: 5px;
        margin-top: 15px;
        .sectionCon{
            // height: 13vh;
            // overflow: auto;
            border-radius: 5px 5px 0 0;
            background-color: #fff;
            padding: 14px;

            >article{
                // border-radius: 4px;
                margin-bottom: 10px;
                border-bottom: 1px solid rgba(229, 229, 229, 1);
                &:last-child{
                    border-bottom: none;
                }
                >h4{
                    height: 22px;
                    font-size: 18px;
                    font-weight: 600;
                    color: #1F2126;
                    line-height: 22px;
                    margin-bottom: 20px;
                }
                .item{
                    font-size: 14px;
                    font-weight: 400;
                    color: #4E5969;
                    margin-bottom: 4px;
                    // margin-top: 15px;
                    margin-bottom: 15px;
                    display: flex;

                    .itemLeft{
                        // flex: 2;
                        display: inline;
                        // width: 80px;
                    }
                    .itemRight{
                        display: inline;
                        // width: calc(100% - 80px);
                        >b{
                            font-weight: 500;
                        }
                        >p{
                            //display: inline;
                        }
                    }
                }
                .homeDetail_top{
                    display: flex;
                    .homeDetail_top_left{
                        .entLogo{
                            background-color: aliceblue;
                            //border-radius: 50%;
                            width: 70px;
                            height: 70px;
                        }
                    }
                    .homeDetail_top_right{
                        flex: 1;
                        padding-left: 8px;
                        >h5{
                            font-size: 16px;
                            font-weight: 600;
                            margin: 0;
                        }
                        >p{
                            color: #4E5969;
                            font-size: 14px;
                            margin: 8px 0;
                            >span{
                                margin-right: 4px;
                            }
                        }
                    }
                }
                .homeDetail_con{
                    margin-top: 15px;
                    font-size: 14px;
                    color: #4E5969;
                    overflow: hidden;
                }
            }
        }
        .blockHeight{
            height: 160px;
            background-color: #fff;
            border-radius: 0 0 5px 5px ;
        }
    }
    
    .send{
        position: sticky;
        bottom: 0;
        left: 0;
        width: 100%;
        >button{
            background-color: #00143D;
            color: #fff;
            font-size: 20px;
            padding: 8px 0;
        }
    }
}
.loading{
    text-align: center;
    height: 100%;
    margin-top: 30%;
}