.verify{
  height: 94%;
  >h1{
    font-weight: 500;
    padding: 15px 0 0;
    text-align: center;
    color: rgba(0, 0, 0, 1);
    font-size: 18px;
  }
  .inform{
    background-color: #fff;
    border-radius: 12px;
    width: 90%;
    margin: 20px 5%;
    padding: 15px 0;
    box-shadow: 0px 2px 12px 3px rgba(227, 227, 227, 0.5);
    >aside{
      padding: 0 15px;
      font-size: 16px;
      >span{
        color:rgba(164, 164, 164, 1);
        display: inline-block;
        margin: 5px 15px;
      }
      >p{
        color: rgba(0, 0, 0, 1);
        display: inline-block;
        margin: 5px 0;
      }
    }
    >h1{
      font-weight: 500;
      padding: 0 15px;
      font-size: 15px;
      color: rgba(0, 0, 0, 1);
    }
    .position{
      margin-top: 15px;
      padding: 0 15px;
      >section{
        display: flex;
        .name{
          font-size: 18px;
          max-width: 400px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: rgba(0, 0, 0, 1);
        }
        .money{
          flex: 1;
          font-size: 18px;
          vertical-align: middle;
          text-align: right;
          color: rgba(16, 38, 254, 1);
        }
      }
      .listType{
        margin-top: 5px;
        >p{
          display: inline-block;
          margin: 5px 0;
          >span{
            background-color:rgba(238, 238, 238, 1);
            color: rgba(0, 0, 0, 1);
            display: inline-block;
            margin-right: 8px;
            padding: 6px 8px;
            border-radius: 15px;
            font-size: 12px;
          }
        }
      }
      .company{
        margin: 10px 0;
        display: flex;
        border-top: 1px solid rgba(204, 204, 204, 1);
        padding-top: 15px;
        >img{
          width: 60px;
          height: 60px;
          margin-right: 20px;
          //border-radius: 50%;
        }
        .companyInform{
          flex: 1;
          margin: 10px 0;
          >h2{
            font-size: 14px;
            color: rgba(0, 0, 0, 1);
            margin: 0;
          }
          >p{
            color: rgba(81, 81, 81, 1);
            font-size: 12px;
            margin: 5px 0;
          }
        }
      }
    }
  }
  .informHeight{
    //height: calc(100% - 150px);
  }
  .btn{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(180deg, rgba(251, 253, 255, 0) 0%, rgba(255, 255, 255, 0.78) 38.04%, rgba(245, 251, 255, 1) 100%);
    >aside {
      width: 92vw;
      margin-left: 4vw;
      padding: 23px 0;
      >button{
        display: inline-block;
        width: 45%;
        padding: 15px 0;
        border-radius: 80px;
        &:active{
          opacity: 0.5;
        }
      }
      .check{
        margin-right: 5%;
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 23px 7px rgba(212, 212, 212, 0.28);
      }
      .over{
        margin-left: 5%;
        background: linear-gradient(212.76deg, rgba(0, 133, 255, 1) 0%, rgba(48, 67, 255, 1) 100%);
        box-shadow: 0px 3px 23px 7px rgba(138, 199, 255, 0.52);
        color: #fff;
      }
    }
  }
}
.loading{
  text-align: center;
  height: 100%;
  margin-top: 30%;
}