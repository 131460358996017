.aiHome{
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  .chatCon {
    position: absolute;
    top: 10vh;
    right: 4vw;
    width: 52vw;
    height: 75vh;
    overflow: auto;
    padding-bottom: 10px;
    &::-webkit-scrollbar { width: 0 !important }
    .chatItem{
      transform: translateX(0px) translateY(0px) translateZ(1px);
      // max-height: 70vh;
      overflow: auto;
      height: 100%;
      .itemCon{
        margin: 10px 0 15px;
        font-size: 15px;
        overflow: auto;
        .userCon{
          text-align: right;
          .bgBlue{
            background-color: rgba(15, 38, 254, 0.65);
            color: #fff;
            border-radius: 20px 20px 0 20px;
          }
        }
        .robotCon{
          margin-bottom: 10px;
        }
        .robotItem{
          max-width: 74%;
          text-align: left;
          display: inline-block;
          padding: 12px 15px;
          color: rgba(0, 0, 0, 1);
          background-color: rgba(255, 255, 255, 0.9);
          border-radius: 20px 20px 20px 0;
          word-break: break-all;
          >button{
            width: 100%;
            font-size: 16px;
            margin: 10px 0;
            padding: 5px;
            border-radius: 30px;
            color: rgba(24, 51, 229, 1);
            border: 1px solid rgba(15, 38, 254, 0.3);
          }
        }
      }
    }
  }
  .bottomCon{
    position: absolute;
    bottom: 4vh;
    left: 5%;
    width: 90%;
    .begin{
      text-align: center;
      >button{
        background: rgba(66, 120, 255, 1);
        color: #fff;
        font-size: 16px;
        padding: 13px 35px;
      }
    }
    .answer{
      display: flex;
      align-items: center;
      >aside{
        flex: 1;
        align-items: center;
        >img{
          width: 60px;
          height: 60px;
          display: inline-block;
        }
        >span{
          display: inline-block;
          margin-left: 10px;
          color: rgba(66, 120, 255, 1);
        }
        >button{
          background: rgba(66, 120, 255, 1);
          color: #fff;
          padding: 13px 10px;
        }
      }
      .answerL{
        display: flex;
        align-items: center;
      }
      .answerR{
        text-align: right;
      }
    }
  }
  .beginLoading{
    position: absolute;
    bottom: 12vh;
    left: 5%;
    width: 90%;
    text-align: center;
    >section{
      text-align: center;
    }
    >p{
      color: rgba(0, 0, 0, 1);
      margin: 5px 0 20px;
    }
  }
}