.loadingWrap {
    width: 100%;
    height: 100vh;
    position: fixed;
    inset: 0;
    z-index: 2000;
    // background-color: #000;
    // background: url("@/static/img/loading.gif");
    // background-size: cover;
    // background-position: center;
    // background-repeat: no-repeat;
    // background-attachment: fixed;

    .canvas_wrap {
        width: 100%;
        height: 100%;
    }

    .loadingImg {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: url('../../assets/img/bg.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top;

        >img {
            width: 70px;
            height: 46px;
            margin-top: -40%;
        }

        .tipsInfo {
            font-size: 17px;
            font-weight: 600;
	        margin-top: 30px;
            color: #1126FF;
        }
    }

    .lineLoad {
        position: absolute;
        bottom: 5%;
        width: 100%;
        padding: 2%;
        box-sizing: border-box;
    }

    /deep/ .u-size-default {
        border-radius: 50px !important;
    }

    /deep/ .u-btn--primary {
        border-color: #1025FE;
        background-color: #1025FE;
    }
}
:global{
    .adm-progress-bar-text {
        width: 25px;
    }

    .ant-progress-inner {
        transition: width 0.3s ease-in-out; /* 添加过渡效果 */
      }
}

.myProgress {
    width: 100%;
    background-color: #e2e2e2;
    border-radius: 20px;
}
.myBar {
    width: 10%;
    height: 35px;
    line-height: 35px;
    background-color: #1677ff;
    text-align: center;
    color: white;
    border-radius: 20px;
}