.pageCenter{
  width: 100%;
  margin-left: calc(50% - 1rem);
}
.loader {
  --dim: 2rem;
  width: var(--dim);
  height: var(--dim);
  position: relative;
  animation: spin988 2s linear infinite;
}

.loader .circle {
  --color: #1677ff;
  --dim: 0.8rem;
  width: var(--dim);
  height: var(--dim);
  border-radius: 50%;
  position: absolute;
}

.loader .circle:nth-child(1) {
  top: 0;
  left: 0;
  background: rgba(74, 91, 254, 1);
}

.loader .circle:nth-child(2) {
  top: 0;
  right: 0;
  background: rgba(63, 106, 254, 1);
}

.loader .circle:nth-child(3) {
  bottom: 0;
  left: 0;
  background: rgba(48, 126, 254, 1);
}

.loader .circle:nth-child(4) {
  bottom: 0;
  right: 0;
  background: rgba(32, 148, 254, 1);
}

@keyframes spin988 {
  0% {
    transform: scale(1) rotate(0);
  }

  20%, 25% {
    transform: scale(1.3) rotate(90deg);
  }

  45%, 50% {
    transform: scale(1) rotate(180deg);
  }

  70%, 75% {
    transform: scale(1.3) rotate(270deg);
  }

  95%, 100% {
    transform: scale(1) rotate(360deg);
  }
}