.createResume{
  padding: 0 20px;
  background: rgba(255, 255, 255, 0.83);
  height: 80vh;
  >h1{
    font-weight: 500;
    padding: 15px 0 0;
    text-align: center;
    color: rgba(0, 0, 0, 1);
    font-size: 18px;
  }
  >article{
    border-radius: 5px;
    background-color: #fff;
    padding: 20px;
    box-shadow: 0px 2px 4px  rgba(0, 0, 0, 0.25);
    >aside{
      padding: 10px 0 15px;
      border-bottom: 1px solid rgba(128, 128, 128, 1);
      display: flex;
      font-size: 16px;
      &:last-child{
        border-bottom: none;
      }
      >p{
        width: 80px;
        font-size: 15px;
        color: rgba(0, 0, 0, 1);
        margin: 8px 0;
        vertical-align: middle;
      }
      >section{
        width: calc(100% - 90px);
        display: flex;
        justify-content: end;
        align-items: center;
        font-size: 14px;
        >p{
          color: rgba(254, 124, 15, 1);
          font-size: 14px;
          margin-left: 10px;
          display: inline-block;
          width: 70px;
        }
        >input{
          padding: 10px 5px;
          font-size: 14px;
          width: 100%;
        }
        .code{
          max-width: calc(100% - 90px);
        }
        >button{
          margin-left: 10px;
          display: inline-block;
          width: 70px;
          font-size: 12px;
          background-color: rgba(0, 51, 255, 1);
          color: #fff;
          vertical-align: middle;
          padding: 8px 3px;
          border-radius: 20px;
        }
      }
    }
  }
  .agree{
    margin: 25px 0 5px;
    .radioBtn{
      color: rgba(175, 175, 175, 1);

    }
    .strBlue{
      color: rgba(0, 51, 255, 1);
    }
  }
  .btn{
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    >aside{
      width: 92vw;
      margin-left: 4vw;
      padding: 23px 0;
      >button{
        display: inline-block;
        width: 40%;
        padding: 15px 0;
        border-radius: 80px;
      }
      .cancel{
        background-color: #fff;
        margin: 0 5%;
        border: 1px solid rgba(0, 0, 0, 1);
      }
      .send{
        color: #fff;
        background-color: rgba(0, 51, 255, 1);
        margin-left: 5%;
      }
      .grayCol{
        background: rgba(172, 172, 172, 1);
        margin-left: 5%;
        color: #fff;
      }
    }
  }
}