.link{
  width: 100vw;
  height: 100vh;
  border-radius: 20px 20px 0 0;
  background: #4153f4;
  .next{
    z-index: 5000;
    padding-top: 15px;
    background: #4153f4;
    >img{
      width: 50px;
      height: 50px;
      padding-top: 8px;
    }
  }
}